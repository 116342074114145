import React from 'react';

const MailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <path
      fill="inherit"
      fillRule="nonzero"
      d="M21.5 0h-19A2.5 2.5 0 0 0 0 2.5v19A2.5 2.5 0 0 0 2.5 24h19a2.5 2.5 0 0 0 2.5-2.5v-19A2.5 2.5 0 0 0 21.5 0zm-2.289 18.011c-.024.057-.104.189-.334.189H5.123c-.23 0-.31-.133-.334-.189-.023-.058-.061-.208.104-.374l4.361-4.371a.497.497 0 0 1 .707 0l1.969 1.97s.038.024.07.024c.032 0 .07-.024.07-.024l1.969-1.967a.498.498 0 0 1 .706.001l4.361 4.366c.165.167.128.317.105.375zM4.8 15.289V8.711c0-.097 0-.354.231-.354.1 0 .173.064.233.125l3.144 3.166c.188.188.177.505-.022.705l-3.12 3.165c-.062.062-.135.126-.234.126-.232 0-.232-.258-.232-.355zm10.858-3.642l3.075-3.164c.062-.062.136-.127.235-.127.231 0 .231.258.231.354v6.595c0 .1.01.209-.13.304s-.274-.031-.335-.091l-3.079-3.167a.503.503 0 0 1 .003-.704zm3.449-5.285l-6.755 6.8a.497.497 0 0 1-.706 0L4.891 6.361c-.165-.165-.127-.316-.104-.373.024-.058.104-.189.334-.189h13.754c.23 0 .31.132.334.189.025.058.063.209-.102.374z"
    />
  </svg>
);

export default MailIcon;
